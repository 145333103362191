var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-15 px-7"},[_c('v-row',[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"d-flex justify-end pb-6 px-8",attrs:{"cols":"12"}},[(this.adminAccess=='Super Admin')?_c('v-btn',{staticClass:"px-7 text-capitalize",staticStyle:{"border-radius":"10px"},attrs:{"color":"#38227A","dark":"","height":"45px"},on:{"click":function($event){return _vm.toggleAudienceModal({ show: true, type: 'add' })}}},[_c('span',[_vm._v("Compose Manual Email")])]):_vm._e()],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{staticClass:"d-flex justify-end pb-6 px-8",attrs:{"cols":"12"}},[(this.adminAccess=='Super Admin' )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mobile-menu"},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,false,266860307)},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"active-class"}},[_c('v-list-item',{on:{"click":function($event){return _vm.toggleAudienceModal({ show: true, type: 'add' })}}},[_c('v-list-item-title',[_vm._v("Compose Manual Email")])],1)],1)],1)],1):_vm._e()],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.dataOptions,"loading-text":"Loading... Please wait","mobile-breakpoint":"0","items-per-page":_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.ITEMS_PER_PAGE],"server-items-length":_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.ITEMS_PER_PAGE]},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"tableItem"},[_vm._v(_vm._s(props.item.date))]),_c('div',{staticClass:"tableItem"},[_vm._v(_vm._s(props.item.time))])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"tableItem"},[_vm._v(_vm._s(props.item.email_subject))])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"tableItem"},[_c('span',{staticStyle:{"color":"#757575"}},[_vm._v(_vm._s(props.item.total_recipients))])])]),_c('td',{staticClass:"text-center py-4"},[_c('div',{staticClass:"tableItem"},[_vm._v(_vm._s(props.item.sent_to))])]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("view")])])],1)],1)])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-row',{staticClass:"pt-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4",attrs:{"align-self":"center","cols":"12","xs":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"\n              justify-center justify-md-start\n              d-flex\n              px-5\n              tableHeader-text\n            "},[_c('div',{staticClass:"alignSelf-center mr-3 pt-2"},[_vm._v("Show")]),_c('div',{staticClass:"alignSelf-center mr-5 pb-2",staticStyle:{"width":"60px"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPageList,"hide-details":"","dense":""},model:{value:(
                  _vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.ITEMS_PER_PAGE]
                ),callback:function ($$v) {_vm.$set(_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO], _vm.$API_KEYS.ITEMS_PER_PAGE, $$v)},expression:"\n                  tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]\n                "}})],1),_c('div',{staticClass:"alignSelf-center pt-2"},[_vm._v(" "+_vm._s(_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.PAGE_NUMBER])+" of "+_vm._s(_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.TOTAL_PAGE])+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4","md":"4"}},[_c('div',[_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.TOTAL_PAGE],"total-visible":5,"color":"#D30024"},model:{value:(_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.PAGE_NUMBER]),callback:function ($$v) {_vm.$set(_vm.tableInfo[_vm.$API_KEYS.PAGE_INFO], _vm.$API_KEYS.PAGE_NUMBER, $$v)},expression:"tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]"}})],1)])],1)]},proxy:true}])}),_c('audience-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }